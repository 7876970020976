<script>
/******************************************************************************
 * shows a section of the current area including (optional) headline
 *****************************************************************************/
export default {
    props: {
        hdl: {
            type: String,
            default: ""
        }
    }
};
</script>

<template>
    <section class="screen-section">
        <header v-if="hdl">
            <h2>{{ hdl }}</h2>
        </header>
        <slot />
    </section>
</template>



<style lang="scss" scoped>
    .screen-section {
        margin: 0 0 #{map-get($sizes, "base") * 0.6} 0;

        &:last-child {
            margin-bottom: 0;
        }

        @include respond-to("medium") {
            margin: 0 0 #{map-get($sizes, "base") * 1.2} 0;
        }
    }

    h2 {
        padding: 0 0 #{map-get($sizes, "base") * 0.6} 0;
        border-bottom: map-get($sizes, "border") solid map-get($client, "dividerBg");
        margin: 0 0 #{map-get($sizes, "base") * 0.6} 0;

        color: palette("brand");

        font-size: #{map-get($sizes, "base") * 1.5};
        font-weight: 700;

        @include respond-to("medium") {
            margin: 0 0 #{map-get($sizes, "base") * 1.2} 0;
        }
    }
</style>

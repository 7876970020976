/******************************************************************************
 *
 * formatting helper
 *
 *****************************************************************************/

export const formatReadingValue = numberString => {
    if (typeof numberString === "number") return numberString
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (typeof numberString === "string") return numberString
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return "";
};

<script>
/******************************************************************************
 * METER READING CONSUMPTION Component
 *****************************************************************************/
import {formatReadingValue} from "@/handlers/format";
export default {
    props: {
        consumption: {type: Number, required: true},
        unit: {type: String, required: true},
        postComma: {type: Number, required: true},
        enforce: {type: Boolean, required: true}
    },
    computed: {
        formattedConsumption () {
            if (!this.enforce) {
                return formatReadingValue(this.consumption);
            } else {
                return this.consumption.toFixed(this.postComma)
                    .toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        },
    },
}
</script>

<template>
    <aside class="consumption">
        Ihr Verbrauch seit dem letzten gemeldeten Zählerstand<br>
        <strong>{{ formattedConsumption }} {{ unit }}</strong>
    </aside>
</template>

<style lang="scss" scoped>
.consumption {
    max-width: map-get($breakpoints, "small");
    padding:
        #{map-get($sizes, "base") * 0.3}
        #{map-get($sizes, "base") * 1.5}
        #{map-get($sizes, "base") * 0.3}
        #{map-get($sizes, "base") * 0.5};
    border: map-get($sizes, "border") solid map-get($client, "dividerBg");
    margin: 0 0 #{map-get($sizes, "base") * 0.8} 0;

    list-style: none;

    font-size: 0.9em;
}
</style>

<script>
/******************************************************************************
 * METER ITEM REQUIREMENT Component
 * this shows the requirements for reading values and if they are met.
 *****************************************************************************/
import Icon from "@/components/Icon/Icon";
import {formatReadingValue} from "@/handlers/format";
export default {
    props: {
        meterNumber: {type: String, required: true},
        meterItemNumber: {type: Number, required: true}
    },
    components: { Icon },
    computed: {
        meterItems () {
            return this.$store.getters
                .getMeteringItemsByMeterNumber(this.meterNumber);
        },
        meterItem () {
            return this.meterItems
                .find(meter => meter.meterItemNumber === this.meterItemNumber);
        },
        meterReadingValue () {
            return this.$store.getters.meterReadings
                .find(
                    meter =>
                        meter.meterNumber === this.meterNumber
                        && meter.meterItemNumber === this.meterItemNumber
                ).meterReadingValue;
        },
        lastReadingOk () {
            return this.meterReadingValue !== ""
                && this.meterReadingValue >= this.meterItem.meterReadingValue;
        },
        readingRangeOk () {
            return this.meterReadingValue
                && this.meterReadingValue >= this.meterItem.meterValueMin
                && this.meterReadingValue <= this.meterItem.meterValueMax;
        },
        iconLastReading () { return this.lastReadingOk ? "done" : "warning"; },
        lastValueClass () { return this.lastReadingOk ? "ok" : "error"; },
        lastReadingLabel () {
            return this.lastReadingOk
                ? "Zählerstand mindestens so groß wie der letzte gemeldete."
                : "Zählerstand ist zu klein.";
        },
        iconRange () { return this.readingRangeOk ? "done" : "warning"; },
        rangeClass () { return this.readingRangeOk ? "ok" : "error"; },
        rangeLabel () {
            return this.readingRangeOk
                ? "Zählerstand entspricht den Erwartungen."
                : "Zählerstand entspricht nicht den Erwartungen.";
        },
        lastReadingValue () {
            return formatReadingValue(this.meterItem.meterReadingValue);
        },
        readingRangeMin () {
            return formatReadingValue(this.meterItem.meterValueMin);
        },
        readingRangeMax () {
            return formatReadingValue(this.meterItem.meterValueMax);
        }
    }
}
</script>

<template>
    <ul class="req clearfix">
        <li
            :class="lastValueClass"
            v-if="meterItem.meterReadingValue !== null"
            :aria-label="lastReadingLabel">
            <icon
                v-if="iconLastReading"
                :name="iconLastReading" />
            Letzter Zählerstand:
            <strong>{{ lastReadingValue }}</strong>
            {{ meterItem.meterUnit }}
            vom {{ meterItem.meterReadingDate }}
        </li>
        <li
            :class="rangeClass"
            v-if="meterItem.meterValueMin !== 0"
            :aria-label="rangeLabel">
            <icon
                v-if="iconRange"
                :name="iconRange" />
            Erwartet zwischen
            <strong>{{ readingRangeMin }}</strong>
            {{ meterItem.meterUnit }}
            und
            <strong>{{ readingRangeMax }}</strong>
            {{ meterItem.meterUnit }}
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    .req {
        max-width: map-get($breakpoints, "small");
        padding: 0;
        border: map-get($sizes, "border") solid map-get($client, "dividerBg");
        margin: 0;

        list-style: none;

        font-size: 0.9em;

        &.hide { display: none; }
    }

    li {
        display: block;
        position: relative;

        padding:
            #{map-get($sizes, "base") * 0.3}
            #{map-get($sizes, "base") * 1.5}
            #{map-get($sizes, "base") * 0.3}
            #{map-get($sizes, "base") * 0.5};
        border-bottom: map-get($sizes, "border") solid map-get($client, "dividerBg");

        line-height: 24px; // icon

        &:last-of-type { border-bottom-width: 0; }

        > svg {
            display: block;
            position: absolute;
            right: #{map-get($sizes, "base") * 0.35};
        }

        > strong,
        .range strong {
            font-weight: 500;
        }

        //&.ok > svg { color: palette("state", "success"); }
        &.error > svg { color: palette("state", "warning"); }

        &.ok {
            background-color: palette("state", "success");
            color: #fff;

            > .svg {
                color: #fff;
            }
        }
    }
</style>

/***********************************************************************************************************************
 *
 * VUEX ACTIONS
 *
 * ASYNC
 *
 **********************************************************************************************************************/
import axios from "axios";
import router from "../router";

const ACTIONS = {

    /*
     * SET CUSTOMER NUMBER
     * @param {Object} ctx - Vuex $store context
     * @param String payload
     */
    SET_CUSTOMERNO: function(ctx, payload) {
        ctx.commit("SET_CUSTOMER_NUMBER", payload);
    },

    /*
     * SET METER NUMBER
     * @param {Object} ctx - Vuex $store context
     * @param String payload
     */
    SET_METERNO: function(ctx, payload) {
        ctx.commit("SET_METER_NUMBER", payload);
    },

    /*
     * DO AUTHENTFICATION
     * @param {Object} ctx - Vuex $store context
     * @param {Object} payload
     * @param {String} payload.customerNo
     * @param {String} payload.meterNo
     */
    DO_AUTH: function(ctx, payload) {
        ctx.commit("SET_REQUESTING", true);
        ctx.commit("RESET_ERRORS");
        axios
            .post("/api/auth", payload)
            // we have either found a customer or not
            .then(response => {
                if (response.status === 200) {
                    ctx.commit("SET_REQUESTING", false);
                    if (response.data.error === "1") {
                        ctx.commit(
                            "SET_AUTH_ERROR",
                            "😱 Leider konnten wir für Ihre Eingabe keinen Kunden ermitteln.<br />" +
                            "Bitte überprüfen Sie Ihre Kunden- und Zählernummer."
                        );
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: "error"});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "locked"});
                    } else {
                        // good to go.
                        ctx.commit("RESET"); // fresh new state
                        ctx.commit("SET_CUSTOMER_NUMBER", response.data.customerNo);
                        ctx.commit("SET_METER_NUMBER", response.data.meterNo);
                        ctx.commit("SET_CUSTOMER_DATA", response.data);
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: "ok"});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: ""});
                        router.push({name: "meterReading"});
                    }
                }
            })
            // something went wrong on Laravel's side.
            .catch(error => {
                ctx.commit("SET_REQUESTING", false);
                console.log(error);
                console.error(`${error.response.status} ${error.response.statusText}`);
                switch(error.response.status) {
                    case 429:
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😡 Zuviele Anfragen.<br />" +
                            "Bitte versuchen Sie es in Kürze erneut."
                        );
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                        break;
                }
            });
    },


    /*
     * LOGOUT
     * @param {Object} ctx - Vuex $store context
     */
    LOGOUT: function(ctx) {
        ctx.commit("RESET"); // reset state
        router.push({name: "auth"}); // go to auth page
        // if the input fields exist in the dom, clear them.
        const customerInput = document.getElementById("customerno");
        const meterInput = document.getElementById("meterno");
        if (customerInput) customerInput.value = "";
        if (meterInput) meterInput.value = "";
    },

    /*
     * SET DATE TYPE to "global" || "individual"
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_DATE_TYPE: function(ctx, payload) {
        ctx.commit("SET_DATE_TYPE", payload);
    },

    /*
     * SET GLOBAL READING DATE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_GLOBAL_READING_DATE: function(ctx, payload) {
        ctx.commit("SET_GLOBAL_READING_DATE", payload);
    },

    /*
     * SET METER READING REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload
     */
    SET_READING_REASON: function(ctx, payload) {
        ctx.commit("SET_READING_REASON", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.reason
     */
    SET_IMPLAUSIBLE_REASON: function(ctx, payload) {
        ctx.commit("SET_IMPLAUSIBLE_REASON", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     * @param {String} payload.reason
     */
    SET_IMPLAUSIBLE_REASON_OTHER: function(ctx, payload) {
        ctx.commit("SET_IMPLAUSIBLE_REASON_OTHER", payload);
    },

    /*
     * SET METER READING IMPLAUSIBLE OTHER REASON
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {Number} payload.meterItemNumber
     */
    RESET_IMPLAUSIBLE_REASON: function(ctx, payload) {
        ctx.commit("RESET_IMPLAUSIBLE_REASON", payload);
    },

    /*
     * SET INDIVIDUAL READING DATE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {ISOString} payload.meterReadingDate
     */
    SET_INDIVIDUAL_READING_DATE: function(ctx, payload) {
        ctx.commit("SET_INDIVIDUAL_READING_DATE", payload);
    },

    /*
     * SET READING VALUE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.meterNumber
     * @param {String} payload.meterItemNumber
     * @param {Number} payload.readingValue
     */
    SET_READING_VALUE: function(ctx, payload) {
        ctx.commit("SET_READING_VALUE", payload);
    },

    /*
     * SET READING VALUE
     * @param {Object} ctx - Vuex $store context
     */
    PROCEED_TO_CONFIRM: function(ctx) {
        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "ok"});
        ctx.commit("SET_SECTION_STATUS", {section: "confirm", status: ""});
        router.push({name: "confirm"});
    },

    /*
     * SUBMIT METER READINGS
     * @param {Object} ctx - Vuex $store context
     * @param {Object} payload - XHR post data
     */
    SUBMIT_READING: function(ctx, payload) {
        ctx.commit("SET_REQUESTING", true);
        ctx.commit("RESET_ERRORS");
        axios
            .post("/api/submit", payload)
            .then(response => {
                if (response.status === 200) {
                    ctx.commit("SET_REQUESTING", false);
                    if (response.data.error === 1) {
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                    }
                    if (response.data.status === "ok" && response.data.print) {
                        console.log("server signals no error");
                        ctx.commit("SET_PRINT_FILE", response.data.print);
                        ctx.commit("SET_SECTION_STATUS", {section: "auth", status: ""});
                        ctx.commit("SET_SECTION_STATUS", {section: "meterReading", status: "locked"});
                        ctx.commit("SET_SECTION_STATUS", {section: "confirm", status: "locked"});
                        ctx.commit("SET_SECTION_STATUS", {section: "success", status: "ok"});
                        router.push({name: "success"});
                    }
                }
            })
            // something went wrong on Laravel's side.
            .catch(error => {
                ctx.commit("SET_REQUESTING", false);
                console.error(`${error.response.status} ${error.response.statusText}`);
                switch(error.response.status) {
                    case 429:
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😡 Zuviele Anfragen.<br />" +
                            "Bitte versuchen Sie es in Kürze erneut."
                        );
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        ctx.commit(
                            "SET_SERVER_ERROR",
                            "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.<br />" +
                            "Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Kundenservice."
                        );
                        break;
                }
            });

    },

    /**
     * UPLOAD METER IMAGE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.customerId
     * @param {String} payload.customerNumber
     * @param {String} payload.meterNumber
     * @param {String} payload.meterItemNumber
     * @param {Object} payload.file
     */
    UPLOAD_IMAGE: function(ctx, payload) {
        console.log(`sending "${payload.file.name}" to server`);
        const errorTime = 10 * 1000;
        const config = {
            onUploadProgress: function(ev) {
                let percentCompleted = Math.round( (ev.loaded * 100) / ev.total );
                console.log("uploading ", percentCompleted);
                ctx.commit("SET_IMAGE_PROGRESS", {
                    meterNumber: payload.meterNumber,
                    meterItemNumber: payload.meterItemNumber,
                    percentage: percentCompleted
                });
            }
        };
        let data = new FormData();
        data.append("image", payload.file);
        data.append("customerId", payload.customerId);
        data.append("customerNumber", payload.customerNumber);
        data.append("meterNumber", payload.meterNumber);
        data.append("meterItemNumber", payload.meterItemNumber);
        // reset everything
        ctx.commit( "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] });
        ctx.commit( "SET_IMAGE_FILENAME", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, name: "" });
        ctx.commit( "SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 0 });
        axios
            .post("/api/image/upload", data, config)
            .then( response => {
                console.log("xhr done!", response);
                if (response.data.status === "error" && response.data.errors.length) {
                    ctx.commit("SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 0 });
                    ctx.commit(
                        "SET_IMAGE_ERRORS",
                        {
                            meterNumber: payload.meterNumber,
                            meterItemNumber: payload.meterItemNumber,
                            errors: response.data.errors
                        }
                    );
                    window.setTimeout( () => {
                        ctx.commit(
                            "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] }
                        );
                    },errorTime);
                }
                if (response.data.status === "ok") {
                    console.log("upload successful.");
                    ctx.commit( "SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 100 });
                    ctx.commit(
                        "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] }
                    );
                    ctx.commit(
                        "SET_IMAGE_FILENAME",
                        {
                            meterNumber: payload.meterNumber,
                            meterItemNumber: payload.meterItemNumber,
                            name: payload.file.name
                        }
                    );
                    ctx.commit(
                        "SET_IMAGE_PATHS",
                        {
                            meterNumber: payload.meterNumber,
                            meterItemNumber: payload.meterItemNumber,
                            file: response.data.filePath,
                            thumb: response.data.thumb
                        }
                    );
                }
            })
            // something went wrong on the server
            .catch( error => {
                console.error("error", error, error.status);
                ctx.commit("SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 0 });
                ctx.commit( "SET_IMAGE_FILENAME", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, name: "" });
                if (error.response.status === 413) {
                    ctx.commit(
                        "SET_IMAGE_ERRORS",
                        {
                            meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [
                                "Die Datei ist zu groß um vom Server verarbeitet zu werden."
                            ]
                        }
                    );
                } else {
                    // mainly 500s. server can't reach DB, tables gone etc.
                    ctx.commit(
                        "SET_IMAGE_ERRORS",
                        {
                            meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [
                                "Ihre Anfrage konnte nicht verarbeitet werden. " +
                                "Bitte versuchen Sie es später noch einmal."
                            ]
                        }
                    );
                }
                window.setTimeout( () => {
                    ctx.commit(
                        "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] }
                    );
                },errorTime);
            });
    },

    /**
     * UPLOAD METER IMAGE
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.customerId
     * @param {String} payload.customerNumber
     * @param {String} payload.meterNumber
     * @param {String} payload.filePath
     */
    DELETE_IMAGE: function(ctx, payload) {
        console.log("requesting delete image", payload);
        ctx.commit( "SET_IMAGE_DELETING", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, isDeleting: true });
        axios.post("/api/image/delete", payload)
            .then( response => {
                ctx.commit( "SET_IMAGE_DELETING", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, isDeleting: false });
                if (response.data.status === "ok") {
                    console.log("deletion successful.");
                }
                ctx.commit( "SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 0 });
                ctx.commit( "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] } );
                ctx.commit( "SET_IMAGE_FILENAME", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, name: "" });
                ctx.commit( "SET_IMAGE_PATHS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, file: "", thumb: "" });
            })
            .catch( error => {
                console.error(error);
                console.log("deletion failed");
                ctx.commit( "SET_IMAGE_DELETING", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, isDeleting: false });
                ctx.commit( "SET_IMAGE_PROGRESS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, percentage: 0 });
                ctx.commit( "SET_IMAGE_ERRORS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, errors: [] } );
                ctx.commit( "SET_IMAGE_FILENAME", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, name: "" });
                ctx.commit( "SET_IMAGE_PATHS", { meterNumber: payload.meterNumber, meterItemNumber: payload.meterItemNumber, file: "", thumb: "" });
            });
    },

    /**
     * UPDATE EMAIL
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload - Email
     */
    UPDATE_EMAIL: function(ctx, payload) {
        ctx.commit("UPDATE_EMAIL", payload);
    },

    /**
     * SUBMIT EMAIL
     * @param {Object} ctx - Vuex $store context
     * @param {String} payload.customerId
     * @param {String} payload.customerNo
     * @param {String} payload.email
     */
    SUBMIT_EMAIL: function(ctx, payload) {
        console.log(payload);
        ctx.commit("SET_SUBMITTING_EMAIL", true);
        ctx.commit("SET_EMAIL_ERRORS", []);
        axios.post("/api/email", payload)
            .then(response => {
                console.log("server response:", response);
                if (response.data.status === "error" && response.data.errors) {
                    ctx.commit("SET_EMAIL_ERRORS", response.data.errors);
                }
                if (response.data.status === "ok") {
                    ctx.commit("SET_EMAIL_ERRORS", []);
                    ctx.commit("SET_EMAIL_DONE", true);
                }
            })
            .catch(error => {
                console.error(error);
                ctx.commit("SET_EMAIL_ERRORS", ["Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten."])
            })
            .finally(function () {
                ctx.commit("SET_SUBMITTING_EMAIL", false);
            });
    },

};

export default ACTIONS;

<script>
/******************************************************************************
 * METER
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import ListItem from "../ListItem";
import {formatReadingValue} from "@/handlers/format";
export default {
    props: {
        meterNumber: {type: String, required: true}
    },
    components: { PageSection, ListItem },
    computed: {
        meterItems () {
            return this.$store.getters.getMeteringItemsByMeterNumber(this.meterNumber);
        },
        meterData () { return this.meterItems[0]; },
        mediaType () {
            switch(this.meterData.meterMediaType) {
                case 1:
                    return "Strom";
                case 2:
                    return "Erdgas";
                case 3:
                case 4:
                    return "Wasser";
                case 5:
                    return "Fernwärme";
                default:
                    return "";
            }
        },
        headline () {
            return `${this.mediaType}-Zähler ${this.meterData.meterNumber}`;
        },
        readings () {
            return this.$store.getters.meterReadings
                .filter(meter => meter.meterNumber === this.meterNumber);
        },
        readingDate () {
            const dateType = this.$store.getters.dateType;
            let date;
            if (dateType === "global") {
                date = new Date(this.$store.getters.globalReadingDate);
            } else {
                date = new Date(this.readings[0].meterReadingDate);
            }
            let day = date.getDate();
            let month = date.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            return `${day}.${month}.${date.getFullYear()}`;
        }
    },
    methods: {
        readingLabel(reading) {
            let label = "Gemeldeter Zählerstand";
            const meterItem = this.meterItems.find(meterItem => meterItem.meterItemNumber === reading.meterItemNumber);
            if (this.readings.length !== 1) label += ` Zählwerk ${reading.meterItemNumber}`;
            if (meterItem.obis || meterItem.meterItemDescription) {
                label += " (";
                if (meterItem.obis) label += "Obis " + meterItem.obis;
                if (meterItem.obis && meterItem.meterItemDescription) label += ", ";
                if (meterItem.meterItemDescription) label += meterItem.meterItemDescription;
                label += ")";
            }
            return label;
        },
        readingValue(value) {
            return `${formatReadingValue(value)} ${
                this.meterData.meterUnit ? this.meterData.meterUnit : ""
            }`;
        },
        lastReading (reading) {
            const meterData = this.meterItems
                .find(meter => meter.meterItemNumber === reading.meterItemNumber);
            if (meterData.meterReadingValue === null) return;
            let isValid = meterData.meterReadingValue <= reading.meterReadingValue;
            let symbol = isValid ? "✔" : "⚠";
            return `Letzter Zählerstand: ${formatReadingValue(meterData.meterReadingValue)} ${
                meterData.meterUnit ? meterData.meterUnit : ""
            } ${symbol}`;
        },
        readingRange (reading) {
            const meterData = this.meterItems
                .find(meter => meter.meterItemNumber === reading.meterItemNumber);
            if (!meterData.meterValueMax || !meterData.meterValueMin) return;
            let isValid = reading.meterReadingValue >= meterData.meterValueMin
                && reading.meterReadingValue <= meterData.meterValueMax;
            let symbol = isValid ? "✔" : "⚠";
            //console.log(meterData, isValid);
            return `Zählerstand erwartet zwischen ${formatReadingValue(
                meterData.meterValueMin
            )} und ${formatReadingValue(
                meterData.meterValueMax
            )} ${
                meterData.meterUnit ? meterData.meterUnit : ""
            } ${symbol}`;
        },
        meterImageThumb (reading) {
            return this.$store.getters.meterImages.find(
                meter => meter.meterNumber === reading.meterNumber
                    && meter.meterItemNumber === reading.meterItemNumber
            ).thumb;
        },
        showMeterImage (reading) {
            return this.$store.getters.allowImageUpload && this.meterImageThumb(reading);
        },
        implausibleReason (reading) {
            return reading.implausibleReason || "";
        },
        implausibleReasonOther (reading) {
            return reading.implausibleReasonOther || "";
        },
        consumption (reading) {
            const meterItem = this.meterItems.filter(item => reading.meterItemNumber === item.meterItemNumber)[0];
            console.log("calculate consumption for ", reading.meterNumber + " #", reading.meterItemNumber, meterItem);
            if (reading.meterReadingValue === null || this.meterData.meterReadingValue === null) return;
            const consumption = reading.meterReadingValue - meterItem.meterReadingValue;
            if (this.meterData.enforcePostComma && consumption > 0) {
                return `${consumption.toFixed(this.meterData.postCommaDigits).toString()
                    .replace(".", ",")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${
                    this.meterData.meterUnit ? this.meterData.meterUnit : ""
                }`;
            }
            if (!this.meterData.enforcePostComma && consumption > 0) {
                return `${formatReadingValue(consumption)} ${
                    " " + this.meterData.meterUnit ? this.meterData.meterUnit : ""
                }`;
            }
            return undefined;
        }
    }
}
</script>

<template>
    <page-section :hdl="headline">
        <list-item
            label="Abnahmestelle"
            :value="`${meterData.meterLocationStreet} ${meterData.meterLocationStreetNumber}, ${meterData.meterLocationPoCode} ${meterData.meterLocationCity}`" />
        <list-item
            label="Ablesedatum"
            :value="readingDate" />
        <div
            v-for="reading in readings"
            :key="reading.meterItemNumber"
            class="implausible-reasons">
            <list-item
                :label="readingLabel(reading)"
                :value="readingValue(reading.meterReadingValue)"
                :last-reading="lastReading(reading)"
                :reading-range="readingRange(reading)" />
            <list-item
                v-if="consumption(reading)"
                label="Verbrauch seit dem letzten gemeldeten Zählerstand"
                :value="consumption(reading)" />
            <list-item
                v-if="implausibleReason(reading)"
                label="Grund für unplausiblen Zählerstand"
                :value="implausibleReason(reading)" />
            <list-item
                v-if="implausibleReasonOther(reading)"
                label="Sonstiger Grund"
                :value="implausibleReasonOther(reading)" />
            <list-item
                class="thumbnail"
                v-if="showMeterImage(reading)"
                label="Bild des Zählerstandes"
                :thumb-src="meterImageThumb(reading)" />
        </div>
    </page-section>
</template>


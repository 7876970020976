<script>
/******************************************************************************
 * ZÄHLERSTÄNDE
 *****************************************************************************/
import PageSection from "@/components/PageSection/PageSection";
import CustomerData from "@/components/CustomerData/CustomerData";
import ReadingData from "./ReadingData/ReadingData";
import Meters from "./Meters/Meters";
import NavRow from "@/components/NavRow/NavRow";
import Icon from "@/components/Icon/Icon";
import router from "@/router";
export default {
    data: function () {
        return {
            showProceedInfo: false
        }
    },
    components: { PageSection, CustomerData, ReadingData, Meters, NavRow, Icon },
    computed: {
        proceedDisabled () {
            let isDisabled = false;
            const meterReadings = this.$store.getters.meterReadings.map(reading => {
                const meter = this.$store.getters.getMeterByNumbers(reading);
                reading.lastReadingValue = meter.meterReadingValue;
                reading.min = meter.meterValueMin;
                reading.max = meter.meterValueMax;
                return reading;
            });
            const isImplausible = (meter) => {
                if (meter.min !== 0) {
                    return meter.meterReadingValue < meter.min || meter.meterReadingValue > meter.max;
                } else if (meter.lastReadingValue) {
                    return meter.meterReadingValue < meter.lastReadingValue;
                } else {
                    return false;
                }
            };
            // check if at least one meter has a value
            const hasOneMeterValue = meterReadings.filter(
                reading => reading.meterReadingValue !== undefined && reading.meterReadingValue !== ""
            ).length > 0;
            if (!hasOneMeterValue) isDisabled = true;
            meterReadings.forEach(meter => {
                if (meter.meterReadingValue === "" || meter.meterReadingValue === undefined) {
                    //isDisabled = true; // disabled for empty readings
                    console.log(meter.meterNumber, meter.meterItemNumber, "is empty");
                    // check if there is a meterItemNumber of the same meter that has a value
                    const hasMeterItemWithValue = meterReadings.filter(
                        reading => reading.meterNumber === meter.meterNumber
                            && reading.meterItemNumber !== meter.meterItemNumber
                            && reading.meterReadingValue !== undefined
                            && reading.meterReadingValue !== ""
                    ).length > 0;
                    if (hasMeterItemWithValue) isDisabled = true;
                } else if (isImplausible(meter)) { // reading value exists
                    if ( meter.implausibleReason === "" || meter.implausibleReason === undefined // but implausible reason not selected
                        || ( // or reason = other and no other reason given
                            meter.implausibleReason === "Sonstiges"
                            && meter.implausibleReasonOther === ""
                        )
                    ) isDisabled = true;
                }
            });
            return isDisabled;
        }
    },
    methods: {
        proceed () {
            this.$store.dispatch("PROCEED_TO_CONFIRM");
        },
        checkProceed () {
            console.log("checking proceed.");
            const numAllReadings = this.$store.getters.meterReadings.length;
            const numReadingsWithValues = this.$store.getters.meterReadings.filter(
                reading => reading.meterReadingValue !== undefined && reading.meterReadingValue !== ""
            ).length;
            if (numAllReadings > numReadingsWithValues) {
                console.log(`${numAllReadings - numReadingsWithValues} readings are missing.`);
                this.showProceedInfo = true;
            } else {
                console.log("all readings done.");
                this.$store.dispatch("PROCEED_TO_CONFIRM");
            }
        },
        prev () { router.push({name: "auth"}); }
    }
}
</script>

<template>
    <main>
        <page-section hdl="Zählerstände">
            Bitte geben Sie alle Zählerstände an.
        </page-section>
        <customer-data />
        <reading-data />
        <meters />
        <div
            class="proceed-anyway"
            v-if="showProceedInfo">
            Es fehlen noch Zählerstände. Trotzdem fortfahren?
        </div>
        <nav-row v-if="showProceedInfo">
            <template slot="right">
                <button
                    type="button"
                    @click="proceed"
                    :disabled="proceedDisabled"
                    :aria-disabled="proceedDisabled">
                    Weiter
                    <icon
                        class="icon-next"
                        name="next" />
                </button>
            </template>
        </nav-row>
        <nav-row v-if="!showProceedInfo">
            <template slot="right">
                <button
                    type="button"
                    @click="checkProceed"
                    :disabled="proceedDisabled"
                    :aria-disabled="proceedDisabled">
                    Weiter
                    <icon
                        class="icon-next"
                        name="next" />
                </button>
            </template>
        </nav-row>
    </main>
</template>

<style lang="scss" scoped>
    .icon-next {
        float: right;

        margin: 7px 0 0 5px;
    }

    .proceed-anyway {
        margin: 0 0 5px 0;

        color: palette("state", "error");

        text-align: right;
    }
</style>
